<template>
  <div class="date-range">
    <div class="date-range__row">
      <div class="date-range__col">
        <custom-select
          :value="selectedDateFrom"
          :options="periodFrom"
          :datepicker="true"
          @input="changedDateFrom"
        ></custom-select>
      </div>
      <div class="date-range__col">
        <custom-select
          :value="selectedDateTo"
          :options="periodTo"
          :datepicker="true"
          @input="changedDateTo"
        ></custom-select>
      </div>
    </div>
  </div>
</template>

<script>
import { monthYear, yearMonthDay } from '@/helpers/dateFormats'
import CustomSelect from './ui/CustomSelect'
import dayjs from 'dayjs'

export default {
  name: 'DateRange',

  components: {
    CustomSelect
  },

  props: {
    selectedDateFrom: {
      type: Object,
      required: true
    },
    selectedDateTo: {
      type: Object,
      required: true
    },
    periodFrom: {
      type: Array,
      required: true
    },
    periodTo: {
      type: Array,
      required: true
    }
  },

  methods: {
    changedDateFrom(date) {
      let dateTo = this.selectedDateTo

      if (dayjs(date.code).isAfter(this.selectedDateTo.code))
        dateTo = {
          label: dayjs(date.code).format(monthYear),
          code: dayjs(date.code).endOf('month').format(yearMonthDay)
        }

      this.$emit('changed-dates', {
        dateFrom: date,
        dateTo: dateTo
      })
    },

    changedDateTo(date) {
      let dateFrom = this.selectedDateFrom

      if (dayjs(date.code).isBefore(this.selectedDateFrom.code))
        dateFrom = {
          label: dayjs(date.code).format(monthYear),
          code: dayjs(date.code).startOf('month').format(yearMonthDay)
        }

      this.$emit('changed-dates', {
        dateFrom: dateFrom,
        dateTo: date
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.date-range__row {
  display: flex;
}

.date-range__col {
  width: 130px;
  position: relative;
  &:not(:last-child) {
    margin-right: 9px;
  }
}
</style>
